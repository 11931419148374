<template>
  <div class="row">
    <div class="col-md-6 bg-left">
      <div class="bg-primary vh-100"></div>
    </div>
    <div class="col-md-6">
      <div class="d-flex justify-content-around">
        <div class="container-login">
          <h1 class="fs-24 text-primary fw-500 lh-base mt-4">
            Lupa Kata Sandi
          </h1>
          <p class="fs-14 text-dark">
            Kami akan mengirimkan link untuk mengubah kata sandi ke email anda
            yang terdaftar.
          </p>
          <form
            @submit.prevent="formHandler"
            class="mt-5 needs-validation"
            ref="needsValidation"
            novalidate
          >
            <div class="mb-3">
              <label class="fs-14 fw-400"
                >Email <span class="text-danger">*</span></label
              >
              <input
                type="email"
                v-model="username"
                required
                placeholder="Email anda"
                class="form-control"
              />
              <div class="invalid-feedback">
                Email wajib diisi dengan format yang benar.
              </div>
            </div>
            <div class="mb-5 mt-5">
              <ul class="list-inline button-login">
                <li class="list-inline-item">
                  <button
                    type="submit"
                    class="btn btn-login btn-primary btn-send-email"
                  >
                    KIRIM EMAIL
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 575.98px) {
  .bg-left {
    display: none;
  }

  .row {
    .col-md-6 {
      width: 100vw;
    }
  }

  .container-login {
    margin-bottom: 100%;
    margin-top: 0;
    max-width: 90%;
  }

  .button-login {
    text-align: center;

    .list-inline-item {
      margin-bottom: 20px;
      display: block !important;
    }
  }
}

.btn-login {
  width: 150px;
}

.content {
  padding-bottom: 0 !important;
}

.form-control {
  background: #f1f1f1;
  border-bottom: 1px solid #0c0b0d !important;
}

.showPassword {
  position: absolute;
  right: 0;
  top: 30px;
  cursor: pointer;
}
</style>
<script>
import config from "../../config";
import { mapGetters } from "vuex";

export default {
  asyncData({ app, params, store, route, error }) {
    store.commit("seo/SET_SEO", {
      title: "Lupa Kata Sandi",
      desc: "Lupa kata sandi, kami akan mengirimkan link untuk mengubah kata sandi ke email anda yang terdaftar",
      image: `${config.BASE_URL}/logo.png`,
      url: `${config.BASE_URL}${route.fullPath}`,
      path: `${config.BASE_URL}${route.path}`,
    });
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
  },
  data() {
    return {
      showPassword: false,
      username: null,
    };
  },
  beforeDestroy() {
    this.$recaptcha.destroy();
  },
  methods: {
    async formHandler(event) {
      let form = this.$refs.needsValidation;
      form.classList.add("was-validated");
      if (!form.checkValidity()) {
        event.preventDefault();
        return event.stopPropagation();
      }
      const token = await this.$recaptcha.execute("forgotpassword");
      this.$axios
        .$post(
          `${config.USER_SERVICE_API}/api/auth/forgot`,
          {
            email: this.username,
          },
          {
            headers: {
              recaptcha: token,
            },
          }
        )
        .then((response) => {
          this.$toast.success(
            "Kami telah mengirimkan link untuk mengubah kata sandi ke email anda yang terdaftar."
          );
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },
  },
  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
