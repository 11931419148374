<template>
  <div class="container">
    <h1>Event Saya</h1>
  </div>
</template>

<script>
  export default {
    middleware: 'auth',
  }
</script>
