<template>
  <div class="container">
    <div class="container-register">
      <h1 class="fs-32 fw-500 text-primary">Register</h1>
      <form @submit.prevent="registerHandler" class="needs-validation" ref="needsValidation" novalidate>
        <div class="card border-radius-10 mt-5 border-0">
          <div class="card-body shadow">
            <TitleSection class="mb-3" title="Data Diri" />
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-500">Nama <span class="text-danger">*</span></label>
                  <input type="text" v-model.trim="name" required placeholder="Ketik namamu..." class="form-control"
                    @keypress="validationChar($event)" />
                  <div class="invalid-feedback">Nama lengkap wajib diisi.</div>
                </div>
                <div class="mb-3">
                  <label class="fs-14 fw-500">Tanggal Lahir <span class="text-danger">*</span></label>
                  <input type="date" v-model="birthdate" required placeholder="Ketik tanggal lahir..."
                    class="form-control" />
                  <div class="invalid-feedback">Tanggal lahir wajib diisi.</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-500">Jenis Kelamin <span class="text-danger">*</span></label>
                  <ul class="list-inline mt-3">
                    <li class="list-inline-item">
                      <div class="form-check form-check-register">
                        <input v-model="gender" v-bind:value="'Pria'" required class="form-check-input" type="radio"
                          name="flexRadioDefault" id="flexRadioPria" />
                        <label class="form-check-label fs-14 fw-400 text-dark" for="flexRadioPria">
                          Pria
                        </label>
                      </div>
                    </li>
                    <li class="list-inline-item">
                      <div class="form-check form-check-register">
                        <input v-model="gender" v-bind:value="'Wanita'" required class="form-check-input" type="radio"
                          name="flexRadioDefault" id="flexRadioWanita" />
                        <label class="form-check-label fs-14 fw-400 text-dark" for="flexRadioWanita">
                          Wanita
                        </label>
                      </div>
                    </li>
                  </ul>
                  <div class="invalid-feedback" style="display: block !important; margin-bottom: -12px"
                    v-show="!gender && isFormSubmit">
                    Jenis kelamin wajib diisi.
                  </div>
                </div>
                <div class="mb-3">
                  <label class="fs-14 fw-500">Kota <span class="text-danger">*</span></label>
                  <v-select v-model="cities" placeholder="Pilih kota" :options="$store.state.account.cities.items" :class="!cities && isFormSubmit
                      ? 'border-bottom-1-invalid'
                      : 'border-bottom-1-valid'
                    "></v-select>
                  <div class="invalid-feedback" style="display: block !important" v-show="!cities && isFormSubmit">
                    Kota wajib dipilih.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-radius-10 mt-5 border-0">
          <div class="card-body shadow">
            <TitleSection class="mb-3" title="Informasi Akun" />
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-500">Email <span class="text-danger">*</span></label>
                  <input type="email" v-model.trim="email" required placeholder="Ketik emailmu..." class="form-control" />
                  <div class="invalid-feedback">Email wajib diisi.</div>
                </div>
                <div class="mb-3">
                  <label class="fs-14 fw-500">Nomor Handphone <span class="text-danger">*</span></label>
                  <input v-model.trim="phoneNumber" required type="tel" pattern="^[0]\d{9,12}$"
                    placeholder="Ketik nomor handphonemu..." class="form-control" />
                  <div class="invalid-feedback">
                    Nomor handphone wajib diisi, misal 082100001111.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3 position-relative">
                  <label class="fs-14 fw-500">Kata Sandi <span class="text-danger">*</span></label>
                  <div class="position-relative">
                    <input minlength="6" maxlength="20" v-model="password" required
                      :type="showPassword ? 'text' : 'password'" placeholder="Ketik kata sandimu..."
                      class="form-control sandi" />
                    <em @click="showPassword = !showPassword" class="material-icons showPassword text-dark"
                      :class="!password ? 'set' : ''">{{ showPassword ? "visibility" : "visibility_off" }}</em>
                    <div class="invalid-feedback">
                      Kata sandi wajib diisi, minimal 6 karakter maximal 20
                      karakter.
                    </div>
                  </div>
                </div>
                <div class="mb-3 position-relative">
                  <label class="fs-14 fw-500">Konfirmasi Kata Sandi
                    <span class="text-danger">*</span></label>
                  <div class="position-relative">
                    <input minlength="6" maxlength="20" v-model="rePassword" required
                      :type="showRePassword ? 'text' : 'password'" placeholder="Ketik kata sandimu..."
                      class="form-control sandi" :class="rePassword !== null
                          ? password !== rePassword
                            ? 'border-bottom-1-invalid is-invalid'
                            : 'border-bottom-1-valid'
                          : ''
                        " />
                    <em @click="showRePassword = !showRePassword" class="material-icons showPassword text-dark"
                      :class="password !== rePassword ? 'set' : ''">{{
                        showRePassword ? "visibility" : "visibility_off"
                      }}</em>
                    <div class="invalid-feedback">
                      {{
                        password !== rePassword
                        ? "Konfirmasi sandi salah."
                        : "Isi dengan kata sandi yang anda isi sebelumnya."
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-radius-10 mt-5 border-0">
          <div class="card-body shadow">
            <TitleSection class="mb-3" title="Informasi Tambahan" />
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-500">Pekerjaan <span class="text-danger">*</span></label>
                  <v-select v-model="work" placeholder="Pilih" :options="[
                    { label: 'Mahasiswa', code: 'Mahasiswa' },
                    { label: 'Lainnya', code: 'Lainnya' },
                  ]" :class="!work && isFormSubmit
    ? 'border-bottom-1-invalid'
    : 'border-bottom-1-valid'
  "></v-select>
                  <div class="invalid-feedback" style="display: block !important" v-show="!work && isFormSubmit">
                    Pekerjaan wajib dipilih.
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-show="work && work.label === 'Lainnya'">
                <div class="mb-3">
                  <label class="fs-14 fw-500">Lainnya <span class="text-danger">*</span></label>
                  <input v-model.trim="otherWork" :required="work && work.label === 'Lainnya'" type="text"
                    placeholder="Ketik pekerjaanmu..." class="form-control" />
                </div>
              </div>
              <div class="col-md-6" v-show="work && work.label === 'Mahasiswa'">
                <div class="mb-3">
                  <label class="fs-14 fw-500">Universitas <span class="text-danger">*</span></label>
                  <v-select v-model="university" placeholder="Universitas"
                    :options="$store.state.account.university.items" :class="!university && isFormSubmit
                        ? 'border-bottom-1-invalid'
                        : 'border-bottom-1-valid'
                      "></v-select>
                  <div class="invalid-feedback" style="display: block !important" v-show="!university && isFormSubmit">
                    Universitas wajib dipilih.
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="mb-3">
                  <label class="fs-14 fw-500">Minat <span class="text-danger">*</span></label>
                  <div class="row my-2">
                    <div class="col-6 col-md-3 mb-2" v-for="(item, idx) in $store.state.account.interests
                      .items" :key="idx">
                      <div class="form-check">
                        <input v-model="interest" class="form-check-input" name="flexCheckMinat" type="checkbox"
                          v-bind:value="item" :id="`flexCheckMinat-${item.code}`" :class="{
                            borderRed: interest.length === 0 && isFormSubmit,
                          }" />
                        <label class="form-check-label" :for="`flexCheckMinat-${item.code}`">
                          {{ item.label }}
                        </label>
                      </div>
                    </div>
                    <div class="invalid-feedback" style="display: block !important; margin-bottom: -12px"
                      v-show="interest.length === 0 && isFormSubmit">
                      Minat/Interest wajib diisi.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 privacy-wrapper">
          <div class="form-check d-flex justify-content-center">
            <input v-model="privacy" class="form-check-input me-2" name="privacy-box" type="checkbox" id="privacy-box"
              :class="{
                borderRed: privacy === false && isFormSubmit,
              }" />
            <label class="form-check-label" for="privacy-box">
              Klik kotak ini untuk menyetujui
              <Link :to="{
                name: 'about-us',
                query: {
                  menu: 'privasi',
                },
              }">Privasi</Link>
              dan
              <Link :to="{
                name: 'about-us',
                query: {
                  menu: 'syarat-dan-ketentuan-umum',
                },
              }">Syarat & Ketentuan</Link>
              yang berlaku
            </label>
          </div>
        </div>
        <div class="text-center mt-5">
          <button type="submit" class="btn w-25 border-radius-5 btn-primary">
            LANJUTKAN
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Devider from "../../../components/Devider";
import config from "../../../config";
import { mapGetters } from "vuex";
import TitleSection from "@/components/TitleSection";
import Link from "@/components/Link";

export default {
  components: {
    Devider,
    TitleSection,
    Link
  },
  async asyncData({ app, params, store, route }) {
    store.commit("seo/SET_SEO", {
      title: "Register dengan email",
      desc: "Daftar akun narasi dengan email, dapatkan informasi menarik khusus buat anda.",
      image: `${config.BASE_URL}/logo.png`,
      url: `${config.BASE_URL}${route.fullPath}`,
      path: `${config.BASE_URL}${route.path}`,
    });
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
  },
  data() {
    return {
      name: null,
      gender: null,
      birthdate: null,
      cities: null,
      email: null,
      phoneNumber: null,
      password: null,
      rePassword: null,
      otherWork: null,
      showPassword: false,
      showRePassword: false,
      work: null,
      university: {
        code: 1,
        label: "Universitas Indonesia",
      },
      interest: [],
      privacy: false,
      isFormSubmit: false,
    };
  },
  methods: {
    validationChar(event) {
      let regex = new RegExp("^[a-zA-Z0-9\\s]+$");
      let key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    async registerHandler(event) {
      let form = this.$refs.needsValidation;
      form.classList.add("was-validated");
      this.isFormSubmit = true;

      if (!form.checkValidity()) {
        event.preventDefault();
        return event.stopPropagation();
      }

      if (!this.cities) {
        return false;
      }

      if (!this.work) {
        return false;
      }

      if (this.work !== "Lainnya") {
        if (this.work && !this.university) {
          return false;
        }
      }

      if (this.interest.length == 0) {
        return false;
      }

      let interests = [];
      this.interest.forEach((item) => {
        interests.push({
          id: item.code,
        });
      });

      if (!this.work) {
        return false;
      }

      if (!this.privacy) {
        this.$toast.error(
          "Pastikan Anda telah menyetujui Privasi dan Syarat & Ketentuan yang berlaku sebelum melanjutkan"
        );
        return false;
      }

      let formData = {
        email: this.email,
        fullname: this.name,
        provider: "local",
        password: this.password,
        phoneNumber: this.phoneNumber,
        gender: this.gender === "Pria" ? 1 : 0,
        birthdate: new Date(this.birthdate).toUTCString(),
        city: this.cities.label,
        occupation:
          this.work.label === "Lainnya" ? this.otherWork : this.work.label,
        picture: null,
        university:
          this.work.label === "Lainnya"
            ? null
            : {
              id: this.university.code,
            },
        interests: interests,
        privacy: this.privacy,
      };

      const token = await this.$recaptcha.execute('register')

      this.$axios
        .$post(`${config.USER_SERVICE_API}/api/auth/sign-up`, formData, {
          headers: {
            recaptcha: token
          }
        })
        .then(async (res) => {

        const tokenLogin = await this.$recaptcha.execute('login')
          this.$auth
            .loginWith("local", {
              data: {
                email: formData.email.toLowerCase(),
                password: formData.password,
                provider: "local",
                type: "credential",
              },
              headers: {
                recaptcha: tokenLogin
              }
            })
            .then((response) => {
              this.$auth.setUserToken(response.data.credential.accessToken);
              this.$auth.setUser(response.data.data);

              if (process.client) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "REGISTER",
                  authenticationMethod: "email",
                  userId: response.data.data.id, //this should be replaced with an actual ID
                });
              }

              if (this.$route.query.redirect) {
                window.location.href = this.$router.resolve({ path: this.$route.query.redirect }).href;
              } else {
                window.location.href = this.$router.resolve("/").href;
              }

              this.$toast.success(res.message);
            })
            .catch((e) => {
              this.$toast.error(e.response.data.message);
            });
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },
    async initData() {
      await this.$store.dispatch("account/getListInterests");
      await this.$store.dispatch("account/getListUniversity");
      await this.$store.dispatch("account/getLisCities");
    },
  },
  async mounted() {

    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }

    try {
      await this.$recaptcha.init()
    } catch (e) {
      console.error(e);
    }

    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  border: none !important;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0;

  &:focus {
    box-shadow: none !important;
  }

  &.sandi {

    &:invalid,
    &:valid {
      background-position: right 50px center !important;

      @media only screen and (max-width: 1024px) {
        background-position: right 40px center !important;
      }
    }

    &.is-invalid {
      border-color: #e73638;
      padding-right: calc(1.5em + 1.54rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e73638'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e73638' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.385rem) center;
      background-size: calc(0.75em + 0.77rem) calc(0.75em + 0.77rem);
    }
  }
}

.form-check {
  .form-check-input {
    &.borderRed {
      border-color: red;
    }
  }
}

::v-deep .v-select {
  display: block;

  .vs__dropdown-toggle {
    border: none !important;
    border-bottom: 1px solid #ddd !important;
    border-radius: 0 !important;
  }
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: unset !important;
}

.was-validated {
  .border-bottom-1-invalid {
    border-bottom: 1px solid #e73638 !important;
    border-radius: 0 !important;
  }

  .border-bottom-1-valid {
    border-bottom: 1px solid #009479 !important;
    border-radius: 0 !important;
  }
}

.container-register {
  padding: 2rem 0;
  margin-bottom: 48px;

  @media (max-width: 575.98px) {
    padding: 0.2rem 0 !important;
    margin-top: 20px;
    margin-bottom: 48px !important;
    position: relative;
  }

  h1 {
    margin-top: 30px;

    @media (max-width: 575.98px) {
      text-align: center;
    }
  }
}

.form-check-register {
  margin-bottom: 0.5rem;
}

.content {
  margin-top: 60px !important;
  padding-bottom: 0 !important;

  @media only screen and (max-width: 1024px) {
    margin-top: 120px !important;
  }
}

.showPassword {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #9e9e9e !important;

  &.set {
    top: 35%;
  }
}

.privacy-wrapper {
  .form-check {
    @media only screen and (min-width: 1024px) {
      padding: 0;
    }

    label {
      a {
        color: #212529;
        text-decoration: underline !important;
      }
    }
  }
}
</style>
