<template>
  <div class="row">
    <div class="col-md-6 bg-left">
      <div class="bg-primary vh-100"></div>
    </div>
    <div class="col-md-6">
      <div class="d-flex justify-content-around">
        <div class="container-login">
          <h1 class="fs-40 text-primary fw-500 lh-base mt-4">
            Ganti Kata Sandi
          </h1>
          <p class="fs-14 text-dark">
            Silahkan ganti kata sandi anda, pastikan menggunakan kata sandi yang
            kuat yang terdiri dari huruf besar, angka, dan karakter.
          </p>
          <form
            @submit.prevent="formHandler"
            class="mt-5 needs-validation"
            ref="needsValidation"
            novalidate
          >
            <div class="mb-3 position-relative">
              <label class="fs-14 fw-400"
                >Kata Sandi Baru <span class="text-danger">*</span></label
              >
              <input
                minlength="6"
                maxlength="20"
                v-model="password"
                required
                :type="showPassword ? 'text' : 'password'"
                placeholder="Ketik kata sandimu..."
                class="form-control"
              />
              <em
                @click="showPassword = !showPassword"
                class="material-icons showPassword text-dark"
                >{{ showPassword ? "visibility" : "visibility_off" }}</em
              >
              <div class="invalid-feedback">
                Kata sandi wajib diisi, minimal 6 karakter maximal 20 karakter.
              </div>
            </div>
            <div class="mb-3 position-relative">
              <label class="fs-14 fw-400"
                >Ulangi Kata Sandi Baru
                <span class="text-danger">*</span></label
              >
              <input
                minlength="6"
                maxlength="20"
                v-model="rePassword"
                required
                :type="showRePassword ? 'text' : 'password'"
                placeholder="Ketik kata sandimu..."
                class="form-control"
                :class="
                  password !== rePassword && isFormSubmit
                    ? 'border-bottom-1-invalid'
                    : 'border-bottom-1-valid'
                "
              />
              <em
                @click="showRePassword = !showRePassword"
                class="material-icons showPassword text-dark"
                >{{ showRePassword ? "visibility" : "visibility_off" }}</em
              >
              <div class="invalid-feedback">
                Isi dengan kata sandi yang anda isi sebelumnya.
              </div>
            </div>
            <div class="mb-5 mt-5">
              <ul class="list-inline button-login">
                <li class="list-inline-item">
                  <button type="submit" class="btn btn-login btn-primary">
                    GANTI KATA SANDI
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '../../assets/js/helpers';
import config from "../../config";

export default {
  data() {
    return {
      showPassword: false,
      showRePassword: false,
      password: null,
      rePassword: null,
      isFormSubmit: false,
    };
  },
  methods: {
    async formHandler() {
      let form = this.$refs.needsValidation;
      form.classList.add("was-validated");
      this.isFormSubmit = true;
      if (!form.checkValidity()) {
        event.preventDefault();
        return event.stopPropagation();
      }

      this.$axios
        .$post(
          `${config.USER_SERVICE_API}/api/auth/reset/${this.$route.query.code}${
            this.$route.redirect ? `?url=${this.$route.redirect}` : ""
          }`,
          {
            password: this.password,
          }
        )
        .then((response) => {
          this.$toast.success("Kata Sandi Anda Berhasil Diubah");
          if (this.$route.query.redirect) {
            window.location = helpers.isUrlValid(this.$route.query.redirect) ? this.$route.query.redirect : "https://narasi.tv";
          } else {
            window.location.href = this.$router.resolve("/auth/login").href;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },
  },
  mounted() {
    if (!this.$route.query.code) {
      this.$toast.error("Halaman tidak tersedia");
      window.location.href = this.$router.resolve("/").href;
    }
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 575.98px) {
  .bg-left {
    display: none;
  }
  .row {
    margin: 0;

    .col-md-6 {
      width: 100vw;
    }
  }
  .container-login {
    margin-bottom: 100%;
    margin-top: 30px;
    max-width: 90%;
  }
  .button-login {
    text-align: center;

    .list-inline-item {
      margin-bottom: 20px;
      display: block !important;
    }
  }
}

.btn-login {
  width: 150px;
}

.content {
  margin-top: 0 !important;
  padding-bottom: 0 !important;
}

.form-control {
  background: #f1f1f1;
  border-bottom: 1px solid #0c0b0d !important;
}

.showPassword {
  position: absolute;
  right: 0;
  top: 30px;
  cursor: pointer;
}
</style>
