<template>
  <div class="row">
    <div class="col-md-6 bg-left">
      <div class="bg-primary h-100"></div>
    </div>
    <div class="col-md-6">
      <div class="d-flex justify-content-around">
        <div class="container-login">
          <h1 class="fs-40 text-primary fw-500 lh-base mt-4">Lengkapi Data</h1>
          <p class="fs-14 text-dark">
            Kami membutuhkan beberapa informasi untuk mendukung konten khusus
            untukmu, tenang informasi kamu tidak akan disebarluaskan.
          </p>
          <form
            @submit.prevent="formHandler"
            class="needs-validation mt-5"
            ref="needsValidation"
            novalidate
          >
            <div class="row">
              <div class="card col-md-12 p-3 border-radius-3">
                <div class="mb-3">
                  <label class="fs-14 fw-400"
                    >Email <span class="text-danger">*</span></label
                  >
                  <input
                    readonly
                    required
                    type="email"
                    v-model="form.email"
                    placeholder="Email anda"
                    class="form-control"
                  />
                  <div class="invalid-feedback">Email wajib diisi.</div>
                </div>
                <div class="mb-3">
                  <label class="fs-14 fw-500"
                    >Jenis Kelamin <span class="text-danger">*</span></label
                  >
                  <ul class="list-inline mt-3">
                    <li class="list-inline-item">
                      <div class="form-check form-check-register">
                        <input
                          v-model="form.gender"
                          value="Pria"
                          required
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioPria"
                        />
                        <label
                          class="form-check-label fs-14 fw-400 text-dark"
                          for="flexRadioPria"
                        >
                          Pria
                        </label>
                      </div>
                    </li>
                    <li class="list-inline-item">
                      <div class="form-check form-check-register">
                        <input
                          v-model="form.gender"
                          value="Wanita"
                          required
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioWanita"
                        />
                        <label
                          class="form-check-label fs-14 fw-400 text-dark"
                          for="flexRadioWanita"
                        >
                          Wanita
                        </label>
                      </div>
                    </li>
                  </ul>
                  <div
                    class="invalid-feedback"
                    style="display: block !important; margin-bottom: -12px"
                    v-show="!form.gender && isFormSubmit"
                  >
                    Jenis kelamin wajib diisi.
                  </div>
                </div>
                <div class="mb-3">
                  <label class="fs-14 fw-400"
                    >Tanggal lahir <span class="text-danger">*</span></label
                  >
                  <input
                    required
                    type="date"
                    v-model="form.birthdate"
                    placeholder="Tanggal lahir anda"
                    class="form-control"
                  />
                  <div class="invalid-feedback">Tanggal lahir wajib diisi.</div>
                </div>
                <div class="mb-3">
                  <label class="fs-14 fw-500"
                    >Kota <span class="text-danger">*</span></label
                  >
                  <v-select
                    autocomplete="off"
                    v-model="form.cities"
                    placeholder="Pilih kota"
                    :options="$store.state.account.cities.items"
                    :class="
                      !form.cities && isFormSubmit
                        ? 'border-bottom-1-invalid'
                        : 'border-bottom-1-valid'
                    "
                  ></v-select>
                  <div
                    class="invalid-feedback"
                    style="display: block !important"
                    v-show="!form.cities && isFormSubmit"
                  >
                    Kota wajib dipilih.
                  </div>
                </div>
                <div class="mb-3">
                  <label class="fs-14 fw-400"
                    >Nomor Handphone <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="form.phoneNumber"
                    required
                    type="tel"
                    pattern="^[0]\d{9,12}$"
                    placeholder="Ketik nomor handphonemu..."
                    class="form-control"
                  />
                  <div class="invalid-feedback">
                    Nomor handphone wajib diisi, misal 082100001111.
                  </div>
                </div>
                <div class="mb-3">
                  <label class="fs-14 fw-500"
                    >Pekerjaan <span class="text-danger">*</span></label
                  >
                  <v-select
                    v-model="form.work"
                    placeholder="Pekerjaan"
                    :class="
                      !form.work && isFormSubmit
                        ? 'border-bottom-1-invalid'
                        : 'border-bottom-1-valid'
                    "
                    :options="[
                      { label: 'Mahasiswa', code: 'Mahasiswa' },
                      { label: 'Lainnya', code: 'Lainnya' },
                    ]"
                  ></v-select>
                  <div
                    class="invalid-feedback"
                    style="display: block !important"
                    v-show="!form.work && isFormSubmit"
                  >
                    Pekerjaan wajib dipilih.
                  </div>
                </div>
                <div
                  class="mb-3"
                  v-show="form.work && form.work.label === 'Lainnya'"
                >
                  <label class="fs-14 fw-500"
                    >Lainnya <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="form.otherWork"
                    :required="form.work && form.work.label === 'Lainnya'"
                    type="text"
                    class="form-control"
                    placeholder="Ketik pekerjaanmu..."
                  />
                  <div class="invalid-feedback">
                    Pekerjaan lainnya wajib diisi
                  </div>
                </div>

                <div
                  class="mb-3"
                  v-show="form.work && form.work.label === 'Mahasiswa'"
                >
                  <label class="fs-14 fw-500"
                    >Universitas <span class="text-danger">*</span></label
                  >
                  <v-select
                    v-model="form.university"
                    :class="
                      !form.university && isFormSubmit
                        ? 'border-bottom-1-invalid'
                        : 'border-bottom-1-valid'
                    "
                    placeholder="Universitas"
                    :options="$store.state.account.university.items"
                  ></v-select>
                  <div
                    class="invalid-feedback"
                    style="display: block !important"
                    v-show="!form.university && isFormSubmit"
                  >
                    Universitas wajib dipilih.
                  </div>
                </div>
                <div class="minat">
                  <label class="fs-14 fw-500"
                    >Minat <span class="text-danger">*</span></label
                  >
                  <div class="row">
                    <div
                      class="col-6 col-md-6"
                      v-for="(item, idx) in $store.state.account.interests
                        .items"
                      :key="idx"
                    >
                      <div class="form-check">
                        <input
                          v-model="form.interests"
                          class="form-check-input"
                          name="flexCheckMinat"
                          type="checkbox"
                          v-bind:value="item"
                          :id="`flexCheckMinat-${item.code}`"
                          :class="{
                            borderRed:
                              form.interests.length === 0 && isFormSubmit,
                          }"
                        />
                        <label
                          class="form-check-label"
                          :for="`flexCheckMinat-${item.code}`"
                        >
                          {{ item.label }}
                        </label>
                      </div>
                    </div>
                    <div
                      class="invalid-feedback"
                      style="display: block !important; margin-bottom: -12px"
                      v-show="form.interests.length === 0 && isFormSubmit"
                    >
                      Minat/Interest wajib diisi.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 privacy-wrapper">
              <div class="form-check">
                <input
                  v-model="form.privacy"
                  class="form-check-input me-2"
                  name="privacy-box"
                  type="checkbox"
                  id="privacy-box"
                  :class="{
                    borderRed: form.privacy === false && isFormSubmit,
                  }"
                />
                <label class="form-check-label" for="privacy-box">
                  Klik kotak ini untuk menyetujui
                  <Link
                    :to="{
                      name: 'about-us',
                      query: {
                        menu: 'privasi',
                      },
                    }"
                    >Privasi</Link
                  >
                  dan
                  <Link
                    :to="{
                      name: 'about-us',
                      query: {
                        menu: 'syarat-dan-ketentuan-umum',
                      },
                    }"
                    >Syarat & Ketentuan</Link
                  >
                  yang berlaku
                </label>
              </div>
            </div>
            <div class="mb-5 mt-5">
              <ul class="list-inline button-login">
                <li class="list-inline-item">
                  <button type="submit" class="btn btn-login btn-primary">
                    LANJUTKAN
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import helper from "../../utils/helper";
import Link from "@/components/Link";

export default {
  components: {
    Link
  },
  data() {
    return {
      isFormSubmit: false,
      form: {
        email: null,
        gender: null,
        birthdate: null,
        cities: null,
        phoneNumber: null,
        interests: [],
        privacy: false,
        university: null,
        work: null,
        otherWork: null,
      },
    };
  },
  computed: {
    tempData() {
      return helper.getCookie("narasi-temp") !== ""
        ? JSON.parse(helper.getCookie("narasi-temp"))
        : null;
    },
  },
  methods: {
    formHandler(event) {
      let form = this.$refs.needsValidation;
      form.classList.add("was-validated");
      this.isFormSubmit = true;
      if (!form.checkValidity()) {
        event.preventDefault();
        return event.stopPropagation();
      }

      let interests = [];
      this.form.interests.forEach((item) => {
        interests.push({
          id: item.code,
          title: item.label
        });
      });

      if (!this.form.cities) {
        return false;
      }

      if (!this.form.work) {
        return false;
      }

      if (this.form.work !== "Lainnya") {
        if (!this.form.work && !this.form.university) {
          return false;
        }
      }

      if (this.form.work.code === 'Mahasiswa' && !this.form.university) {
       return;
      }

      if (this.form.interests.length == 0) {
        return false;
      }

      if (!this.form.privacy) {
        this.$toast.error(
          "Pastikan Anda telah menyetujui Privasi dan Syarat & Ketentuan yang berlaku sebelum melanjutkan"
        );
        return false;
      }

      let formData = {
        email: this.form.email,
        gender: this.form.gender === "Pria" ? 1 : 0,
        birthdate: this.form.birthdate,
        city: this.form.cities.label,
        phoneNumber: this.form.phoneNumber,
        occupation:
          this.form.work.label === "Lainnya"
            ? this.form.otherWork
            : this.form.work.label,
        university:
          this.form.work.label === "Lainnya"
            ? null
            : {
                id: this.form.university.code,
                title: this.form.university.label
              },
        provider: this.tempData.data.provider,
        interests: interests,
        privacy: this.form.privacy,
      };

      const api = this.$axios.create({
        headers: {
          Authorization: `Bearer ${this.tempData.credential.accessToken}`,
        },
      });

      window.$nuxt.$store.commit("config/setLoading", true);
      api
        .$patch(`${config.USER_SERVICE_API}/api/users/complete`, formData)
        .then((response) => {
          window.$nuxt.$store.commit("config/setLoading", false);
          this.$toast.success("Terima kasih, data anda sudah lengkap...");
          api
            .$get(`${config.USER_SERVICE_API}/api/users`)
            .then((user) => {
              this.$auth.setUserToken(this.tempData.credential.accessToken);
              this.$auth.setUser(user.data);
              helper.eraseCookie('narasi-temp')
              window.$nuxt.$store.commit("config/setLoading", false);
              window.location.href = this.$router.resolve({
                path: this.$route.query.redirect
                  ? this.$route.query.redirect
                  : "/",
              }).href;
            })
            .catch((e) => {
              window.$nuxt.$store.commit("config/setLoading", false);
              this.$toast.error(
                "Opps, sepertinya email atau nomor handphone yang anda masukkan sudah terdaftar, silangkan menggunakan yg lain..."
              );
            });
        })
        .catch((e) => {
          window.$nuxt.$store.commit("config/setLoading", false);
          this.$toast.error(
            "Opps, sepertinya email atau nomor handphone yang anda masukkan sudah terdaftar, silangkan menggunakan yg lain..."
          );
        });
    },
  },
  mounted() {
    if (this.tempData) {
      this.$store.dispatch("account/getLisCities");
      this.$store.dispatch("account/getListInterests");
      this.$store.dispatch("account/getListUniversity");
      this.form = {
        email: this.tempData.data.email,
        gender: this.tempData.data.gender === null ? "Pria" : "Wanita",
        birthdate: this.tempData.data.birthdate,
        cities: this.tempData.data.city
          ? {
              code: this.tempData.data.city,
              label: this.tempData.data.city,
            }
          : null,
        phoneNumber: this.tempData.data.phoneNumber,
        interests: this.tempData.data.interests.map((data) => {
          return {
            code: data.id,
            label: data.title,
          };
        }),
        privacy: this.tempData.data.privacy,
        university: !this.tempData.data.university
          ? null
          : {
              code: this.tempData.data.university.id,
              label: this.tempData.data.university.name,
            },
        work: {
          code:
            this.tempData.data.occupation === "Mahasiswa"
              ? "Mahasiswa"
              : "Lainnya",
          label:
            this.tempData.data.occupation === "Mahasiswa"
              ? "Mahasiswa"
              : "Lainnya",
        },
        otherWork: this.tempData.data.occupation,
      };
    } else {
      this.$toast.error("Anda tidak mempunyai akses ke halaman ini.");
      window.location.href = this.$router.resolve("/auth/register").href;
    }
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 575.98px) {
  .bg-left {
    display: none;
  }
  // form {
  //   .row > * {
  //     padding-left: 0px !important;
  //     padding-right: 0px !important;
  //   }
  // }
  .row {
    margin: 0;

    .col-md-6 {
      width: 100vw;
    }
  }
  .container-login {
    margin-bottom: 55%;
    margin-top: 30px;
    max-width: 90%;
  }
  .button-login {
    text-align: center;

    .list-inline-item {
      margin-bottom: 20px;
      display: block !important;
    }
  }
}

.form-check {
  .form-check-input {
    &.borderRed {
      border-color: red;
    }
  }
}

.was-validated {
  .border-bottom-1-invalid {
    border-bottom: 1px solid #e73638 !important;
    border-radius: 0 !important;
  }

  .border-bottom-1-valid {
    border-bottom: 1px solid #009479 !important;
    border-radius: 0 !important;
  }
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: unset !important;
}

.btn-login {
  width: 150px;
}

.content {
  margin-top: 0 !important;
  padding-bottom: 0 !important;
}

.form-control {
  background: transparent;
  background-color: transparent;
}

.card {
  border-radius: 6px;
}

.showPassword {
  position: absolute;
  right: 0;
  top: 30px;
  cursor: pointer;
}

.container-login {
  margin-top: 10%;
  .needs-validation {
    .row {
      margin: 0;
      .col-6 {
        padding: 0;
      }
    }
  }
}

.minat {
  .row {
    grid-row-gap: 5px;
  }
}

.privacy-wrapper {
  .form-check {
    display: flex;
    justify-content: flex-start;
    @media only screen and (max-width: 1024px) {
      display: block;
    }
    input {
      @media only screen and (max-width: 1024px) {
        display: inline;
      }
    }
    label {
      @media only screen and (max-width: 1024px) {
        display: inline;
      }
      a {
        color: #212529;
        text-decoration: underline !important;
      }
    }
  }
}
</style>
