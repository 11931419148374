<template>
  <div class="container">
    <div class="login-page">
      <ModalOtp :email="email" :password="password" @registeredModal="modalOtp = $event" />
      <div class="login-container">
        <h1>Login</h1>
        <!-- <p class="desc">
          Masuk di sini untuk bisa ikut event Narasi, Narasi Academy, dan
          personalisasi konten favoritmu.
        </p>
        <div class="btn google" @click="loginSocialMedia('google')">
          <Icons name="google-white" />LOGIN DENGAN GOOGLE
        </div>
        <div class="btn facebook" @click="loginSocialMedia('facebook')">
        <Icons name="facebook-white" />LOGIN DENGAN FACEBOOK
      </div>
        <div class="separator">
          <hr />
          <p>atau</p>
          <hr />
        </div> -->
        <form @submit.prevent="loginHandler" class="needs-validation" ref="needsValidation" novalidate>
          <div class="input-wrapper">
            <label>Email <span class="text-danger">*</span></label>
            <div class="wrapper">
              <input type="email" v-model="email" required placeholder="Email anda" class="form-control" />
              <div class="invalid-tooltip">
                Email wajib diisi dengan format email yang benar.
              </div>
            </div>
          </div>
          <div class="input-wrapper">
            <label>Kata sandi <span class="text-danger">*</span></label>
            <div class="wrapper">
              <input :type="showPassword ? 'text' : 'password'" v-model="password" required
                placeholder="Kata sandi anda" class="form-control with-eye" />
              <Icons :name="showPassword ? 'visibility-on' : 'visibility-off'"
                @click.native="showPassword = !showPassword" />
              <div class="invalid-tooltip">Kata sandi wajib diisi.</div>
            </div>
          </div>
          <button type="submit" class="btn btn-login btn-primary">MASUK</button>
          <Link :to="$route.query.redirect
            ? `/auth/forgot-password?redirect=${$route.query.redirect}`
            : `/auth/forgot-password`
            " class="forget-password">Lupa Kata Sandi
          </Link>
        </form>
        <p class="register-now">
          Belum punya akun?
          <Link class="fw-700" :to="$route.query.redirect
            ? `/auth/register?redirect=${$route.query.redirect}`
            : `/auth/register`
            ">Daftar Sekarang
          </Link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../../config";
import helper from "../../../utils/helper";
import { mapGetters } from "vuex";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ModalOtp from "@/components/modal/Otp";

export default {
  components: {
    Link,
    Icons,
    ModalOtp
  },
  data() {
    return {
      showPassword: false,
      isLoginWithEmail: false,
      email: null,
      password: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!from.fullPath.includes("auth") && !vm.$route.query.redirect) {
        vm.$router.push({ query: { redirect: from.fullPath } });
      } else {
        vm.$router.push({ query: { redirect: vm.$route.query.redirect } });
      }
    });
  },
  asyncData({ app, params, store, route, error }) {
    store.commit("seo/SET_SEO", {
      title: "Login",
      desc: "Login untuk bergabung dengan Narasi, dapatkan informasi menarik khusus buat anda.",
      image: `${config.BASE_URL}/logo.png`,
      url: `${config.BASE_URL}${route.fullPath}`,
      path: `${config.BASE_URL}${route.path}`,
    });
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
  },
  methods: {
    async loginHandler(event) {

      let form = this.$refs.needsValidation;
      form.classList.add("was-validated");
      if (!form.checkValidity()) {
        event.preventDefault();
        return event.stopPropagation();
      }

      const token = await this.$recaptcha.execute("login");

      this.$auth
        .loginWith("local", {
          data: {
            email: this.email.toLowerCase(),
            password: this.password,
            provider: "local",
            type: "credential",
          },
          headers: {
            recaptcha: token
          }
        })
        .then((response) => {
          if (
            !response.data.data.phoneNumber ||
            !response.data.data.email ||
            response.data.data.gender == null ||
            !response.data.data.city ||
            !response.data.data.birthdate ||
            !response.data.data.occupation ||
            !response.data.data.interests.length === 0 ||
            !response.data.data.privacy
          ) {
            this.$toast.info(
              "Anda harus melengkapi data anda terlebih dahulu."
            );
            helper.setCookie("narasi-temp", JSON.stringify(response.data), 1);
            if (this.$route.query.redirect) {
              window.location.href = this.$router.resolve(`/auth/complete-data?redirect=${this.$route.query.redirect}`).href;
            } else {
              window.location.href = this.$router.resolve(`/auth/complete-data`).href;
            }
          } else {
            this.$auth.setUserToken(response.data.credential.accessToken);
            this.$auth.setUser(response.data.data);
            // this.$auth.setUser({
            //   ...this.$store.state.auth.user,
            //   photo: "https://maritho.github.io/assets/images/profile.jpg",
            // });
          }

          if (process.client) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "LOGIN",
              authenticationMethod: "email",
              userId: response.data.data.id, //this should be replaced with an actual ID
            });
          }

          if (this.$route.query.redirect) {
            window.location.href = this.$router.resolve({ path: this.$route.query.redirect }).href;
          } else {
            window.location.href = this.$router.resolve("/").href;
          }

          this.$toast.success("Login menggunakan email berhasil.");
        })
        .catch((e) => {
          this.$toast.error("Maaf, email atau kata sandi salah...");
        });
    },
    redirect(link) {
      window.location.href = this.$router.resolve(link).href;
    },
    loginSocialMedia(provider) {
      let authProvider;
      if (provider === "google") {
        authProvider = new this.$fireModule.auth.GoogleAuthProvider();
        authProvider.addScope(
          "https://www.googleapis.com/auth/userinfo.profile"
        );
        authProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
      } else {
        authProvider = new this.$fireModule.auth.FacebookAuthProvider();
        authProvider.addScope("public_profile");
        authProvider.addScope("email");
      }

      this.$fire.auth.signOut().then((res) => {
        this.$fire.auth.signInWithPopup(authProvider).then(async (data) => {
          if (data.credential) {
            let payload = {
              credential: data.credential.accessToken,
              email: "****",
              password: "****",
              provider: data.additionalUserInfo.providerId.replace(".com", ""),
              type: "social-media",
            };

            const token = await this.$recaptcha.execute("login");

            this.$axios
              .$post(`${config.USER_SERVICE_API}/api/auth/sign-in`, payload, {
                headers: {
                  recaptcha: token,
                },
              })
              .then((localData) => {
                if (
                  !localData.data.phoneNumber ||
                  !localData.data.email ||
                  localData.data.gender == null ||
                  !localData.data.city ||
                  !localData.data.birthdate ||
                  !localData.data.occupation ||
                  !localData.data.interests.length === 0 ||
                  !localData.data.privacy
                ) {
                  this.$toast.info(
                    "Anda harus melengkapi data anda terlebih dahulu."
                  );
                  helper.setCookie("narasi-temp", JSON.stringify(localData), 1);
                  if (this.$route.query.redirect) {
                    window.location.href = this.$router.resolve(
                      `/auth/complete-data?redirect=${this.$route.query.redirect}`
                    ).href;
                  } else {
                    window.location.href =
                      this.$router.resolve(`/auth/complete-data`).href;
                  }
                } else {

                  if (process.client) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "LOGIN",
                      authenticationMethod:
                        data.additionalUserInfo.providerId.replace(".com", ""),
                      userId: localData.data.id, //this should be replaced with an actual ID
                    });
                  }

                  this.$toast.success("Login menggunakan email berhasil.");

                  this.$auth.setUserToken(localData.credential.accessToken).then((res) => {
                    this.$auth.setUser(localData.data);
                    if (this.$route.query.redirect) {
                      window.location.href = this.$router.resolve({ path: this.$route.query.redirect }).href;
                    }
                  })
                }
              })
              .catch((e) => {
                this.$toast.error(
                  "Email atau kata sandi yang anda masukkan salah."
                );
              });
          }
        });
      });
    },
  },
  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  padding: 80px 0 80px 0;

  @media only screen and (max-width: 1024px) {
    padding: 24px 0 24px 0;
  }

  .login-container {
    width: 50%;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      padding: 0 32px;
    }

    h1 {
      font-size: 24px;
      font-weight: 700;
      color: #4a2ca7;
      margin-bottom: 12px;

      @media only screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }

    .desc {
      font-size: 14px;

      @media only screen and (max-width: 1024px) {
        font-size: 13px;
      }
    }

    .btn {
      width: 100%;
      padding: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 700;
      color: #fafafa;
      margin-bottom: 10px;

      @media only screen and (max-width: 1024px) {
        font-size: 13px;
        padding: 10px;
      }

      &.facebook {
        background: #4766af;
      }

      &.google {
        background: #4e83ef;
      }
    }

    .separator {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 21px;

      hr {
        flex: 1;
        color: #616161;
      }

      p {
        font-size: 14px;
        color: #616161;
        margin: 0;

        @media only screen and (max-width: 1024px) {
          font-size: 13px;
        }
      }
    }

    form {
      margin-bottom: 24px;

      .input-wrapper {
        margin-bottom: 32px;

        .wrapper {
          position: relative;

          .form-control {
            background-color: #f1f1f1;
            border-bottom: 1px solid #0c0b0d !important;

            &.with-eye {
              &:-webkit-autofill {
                background-color: #f1f1f1 !important;
              }

              &:invalid {
                padding-right: calc(2.5em + 1.54rem);
                background-position: right calc(1.375em + 0.385rem) center;
              }

              &:valid {
                padding-right: calc(2.5em + 1.54rem);
                background-position: right calc(1.375em + 0.385rem) center;
              }
            }
          }

          .invalid-tooltip {
            background-color: transparent;
            color: rgba(231, 54, 56, 0.9);
          }

          svg {
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }
        }
      }

      .forget-password {
        display: flex;
        width: fit-content;
        margin-left: auto;

        @media only screen and (max-width: 1024px) {
          font-size: 13px;
        }
      }
    }

    .register-now {
      font-size: 14px;
      color: #051c2c;
      text-align: center;
      margin: 0;

      @media only screen and (max-width: 1024px) {
        font-size: 13px;
      }

      a {
        font-weight: 600;
        color: #4a25aa;
      }
    }
  }
}
</style>
