<template>
  <div class="container content-item mt-4 mb-5">
    <ModalCustom
      id="modal-update-password"
      ref="modal-update-password"
      @registeredModal="modalUpdatePassword = $event"
    >
      <div class="modal-content">
        <div class="title-wrapper">
          <div class="title">Ubah Kata Sandi</div>
          <div class="close-modal" data-bs-dismiss="modal" aria-label="Close">
            <Icons name="close-modal-login" />
          </div>
        </div>
        <div class="box">
          <label for="">Kata Sandi Baru</label>
          <div class="input-wrapper">
            <input
              :type="showNewPassword ? 'text' : 'password'"
              name="new-password"
              id="new-password"
              v-model="newPassword"
            />
            <Icons
              name="show-password"
              @click.native="showNewPassword = !showNewPassword"
            />
          </div>
        </div>
        <div class="box">
          <label for="">Konfirmasi Kata Sandi Baru</label>
          <div class="input-wrapper">
            <input
              :type="showConfirm ? 'text' : 'password'"
              name="confirm-new-password"
              id="confirm-new-password"
              v-model="confirmPassword"
              :class="{ wrong: newPassword !== confirmPassword }"
            />
            <Icons
              name="show-password"
              @click.native="showConfirm = !showConfirm"
            />
          </div>
        </div>
        <div class="btn-wrapper">
          <div
            type="button"
            class="btn border-radius-5 fw-700 fs-14 btn-two"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            BATAL
          </div>
          <div
            type="submit"
            class="btn btn-primary border-radius-5 fw-700 fs-14 btn-one"
            :class="{
              disable: newPassword !== confirmPassword || newPassword === '',
            }"
            @click="updatePassword()"
          >
            SIMPAN
          </div>
        </div>
      </div>
    </ModalCustom>
    <ModalCustom
      id="modal-update-phone-number"
      ref="modal-update-phone-number"
      @registeredModal="modalUpdatePhoneNumber = $event"
    >
      <div class="modal-content">
        <div class="title-wrapper">
          <div class="title">Ubah Nomor Handphone</div>
          <div class="close-modal" data-bs-dismiss="modal" aria-label="Close">
            <Icons name="close-modal-login" />
          </div>
        </div>
        <div class="box">
          <label for="">Nomor Handphone Baru</label>
          <div class="input-wrapper">
            <input
              type="number"
              name="new-phone-number"
              id="new-phone-number"
              v-model="newPhoneNumber"
              @keypress="validateNumber"
            />
          </div>
        </div>
        <div class="btn-wrapper">
          <div
            type="button"
            class="btn border-radius-5 fw-700 fs-14 btn-two"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            BATAL
          </div>
          <div
            type="submit"
            class="btn btn-primary border-radius-5 fw-700 fs-14 btn-one"
            :class="{
              disable: newPhoneNumber === '',
            }"
            @click="updatePhoneNumber()"
          >
            SIMPAN
          </div>
        </div>
      </div>
    </ModalCustom>
    <div class="program-nav container">
      <Link
        :to="{
          name: 'account',
        }"
        class="box"
        :class="!$route.query.type ? 'Link-exact-active' : ''"
        >PENGATURAN
      </Link>
    </div>
    <div class="container-user-dashboard">
      <div class="box-user-dashboard mb-4">
        <div
          class="title-wrapper d-flex justify-content-between align-items-center"
        >
          <ul class="list-inline">
            <li class="list-inline-item vertical-bar"></li>
            <li class="list-inline-item fw-700 fs-20 text-primary">PROFIL</li>
          </ul>
          <div class="text-center button-edit-wrapper">
            <div
              @click="(isEdit = !isEdit), moveUp()"
              v-if="!isEdit"
              class="fs-14 cursor-pointer"
            >
              <Icons name="pencil" class="me-1" />
              Edit Profil
            </div>
          </div>
        </div>
        <form>
          <div class="row">
            <div class="col-md-3 text-center">
              <div class="mb-3 d-flex justify-content-center">
                <div v-if="picturePreview">
                  <img class="image-data" :src="picturePreview" />
                </div>
                <div class="image-data" v-else>
                  <ProfilePicture
                    :initial="$store.state.auth.user.fullname.substr(0, 1)"
                    :large="true"
                  />
                </div>
              </div>
              <div class="custom-file mb-3 mx-auto">
                <input
                  type="file"
                  id="upload"
                  @change="previewImage"
                  accept="image/*"
                  hidden
                /><label class="btn-file fs-14 fw-500" for="upload"
                  >Pilih Gambar</label
                >
              </div>
              <div class="mb-3">
                <p class="fs-14">
                  Ukuran Maks: 5MB <br />
                  Format: .JPEG, .PNG
                </p>
              </div>
            </div>
            <div class="col-md-9">
              <div class="row mb-3 row-input">
                <div class="col-md-6 col-sm-12 input-wrapper">
                  <label
                    for="exampleFormControlInput1"
                    class="form-label fw-500 fs-16"
                    >Nama Lengkap<span class="required-field"></span
                  ></label>
                  <input
                    type="text"
                    :disabled="!isEdit"
                    v-model="form.fullname"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder="John Murphy"
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <label
                    for="exampleFormControlInput1"
                    class="form-label fw-500 fs-16"
                    >Jenis Kelamin<span class="required-field"></span
                  ></label>
                  <div class="mt-2">
                    <div class="form-check form-check-inline">
                      <input
                        :disabled="!isEdit"
                        v-model="form.gender"
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="1"
                      />
                      <label class="form-check-label fs-14" for="inlineRadio1"
                        >Pria</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        :disabled="!isEdit"
                        v-model="form.gender"
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        value="0"
                      />
                      <label class="form-check-label fs-14" for="inlineRadio2"
                        >Wanita</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3 row-input">
                <div class="col-md-6 col-sm-12 input-wrapper">
                  <label
                    for="exampleFormControlInput1"
                    class="form-label fw-500 fs-16"
                    >Tanggal Lahir<span class="required-field"></span
                  ></label>
                  <input
                    type="date"
                    :disabled="!isEdit"
                    v-model="form.birthdate"
                    class="form-control"
                    placeholder="Tanggal Lahir"
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <label
                    for="exampleFormControlInput1"
                    class="form-label fw-500 fs-16"
                    >Kota<span class="required-field"></span
                  ></label>
                  <v-select
                    :selectable="() => isEdit"
                    :class="{ disable: !isEdit }"
                    v-model="form.city"
                    placeholder="Pilih kota"
                    :options="!isEdit ? [] : $store.state.account.cities.items"
                  ></v-select>
                </div>
              </div>
              <div class="mb-3">
                <label
                  for="exampleFormControlInput1"
                  class="form-label fw-500 fs-16"
                  >Pekerjaan</label
                >
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <v-select
                      v-model="form.work"
                      placeholder="Pilih Pekerjaan"
                      :class="{ disable: !isEdit }"
                      :options="[
                        { label: 'Mahasiswa', code: 'Mahasiswa' },
                        { label: 'Lainnya', code: 'Lainnya' },
                      ]"
                    >
                    </v-select>
                  </div>
                  <div
                    class="col-md-6 col-sm-12"
                    v-if="form.work && form.work.label === 'Lainnya'"
                  >
                    <div class="mb-3 jobs">
                      <input
                        v-model="form.otherWork"
                        :class="{ disable: !isEdit }"
                        type="text"
                        placeholder="Ketik pekerjaanmu..."
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="form.work && form.work.label === 'Mahasiswa'"
                  >
                    <div class="mb-3 jobs">
                      <v-select
                        v-model="form.university"
                        :class="{ disable: !isEdit }"
                        placeholder="Universitas"
                        :options="$store.state.account.university.items"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label fw-500 fs-16">Bio</label>
                <textarea
                  :disabled="!isEdit"
                  class="form-control rounded-two"
                  :class="{ danger: form.bio.length === 140 }"
                  rows="4"
                  :maxlength="140"
                  v-model="form.bio"
                  placeholder="Ketik biografi singkat mengenai dirimu di sini ... "
                ></textarea>
                <div
                  class="counter-text text-center text-primary mt-2"
                  :class="[
                    { warningText: form.bio.length >= 120 },
                    { dangerText: form.bio.length === 140 },
                  ]"
                  v-text="form.bio.length + '/' + 140"
                ></div>
              </div>
              <div class="mb-3">
                <label
                  for="exampleFormControlInput1"
                  class="form-label fw-500 fs-16"
                  >Minat</label
                >
                <div class="row">
                  <div class="col-12">
                    <ul class="list-inline">
                      <li
                        class="list-inline-item"
                        v-for="(item, idx) in $store.state.account.interests
                          .items"
                        :key="idx"
                      >
                        <div
                          :class="
                            form.interests.filter(
                              (int, i) => int.code === item.code
                            ).length > 0
                              ? 'btn-active_'
                              : ''
                          "
                          @click="isEdit ? toggleInterest(item) : ''"
                          class="btn-interest-top mb-2 fw-400 fs-14"
                        >
                          {{ item.label }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-end button-edit-wrapper">
              <div
                @click="isEdit = !isEdit"
                v-if="isEdit"
                class="btn border-radius-5 fw-700 fs-14 btn-two"
              >
                BATAL
              </div>
              <div
                @click="(isEdit = !isEdit), updateProfile()"
                v-if="isEdit"
                class="btn btn-primary border-radius-5 fw-700 fs-14 btn-one"
              >
                SIMPAN
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="box-user-dashboard">
        <div class="">
          <ul class="list-inline">
            <li class="list-inline-item vertical-bar"></li>
            <li class="list-inline-item fw-700 fs-20 text-primary">
              KEAMANAN PRIVASI
            </li>
          </ul>
        </div>
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="label">Email <span class="text-danger">*</span></td>
              <td>{{ $store.state.account.user.items.email }}</td>
            </tr>
            <tr>
              <td class="label">
                Kata Sandi <span class="text-danger">*</span>
              </td>
              <td class="password-hash">
                <p class="password-text">
                  {{
                    isEdit
                      ? $store.state.account.user.items.password
                      : "********"
                  }}
                </p>
                <div
                  v-if="$store.state.account.user.items.provider !== 'google'"
                  @click="modalUpdatePassword.show()"
                  class="update-password"
                >
                  <Icons name="pencil" />
                  <p class="update">Ubah Kata Sandi</p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="label">No HP <span class="text-danger">*</span></td>
              <td class="password-hash">
                <p class="password-text">
                  {{ $store.state.account.user.items.phoneNumber }}
                </p>
                <div
                  @click="modalUpdatePhoneNumber.show()"
                  class="update-password"
                >
                  <Icons name="pencil" />
                  <p class="update">Ubah Nomor HP</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ModalCustom from "@/components/ModalCustom";
import ProfilePicture from "@/components/ProfilePicture";

export default {
  components: {
    ProfilePicture,
    Link,
    Icons,
    ModalCustom
  },
  middleware: "auth",
  async asyncData({ app, params, store, route }) {
    try {
      await store.dispatch("account/getUser");
    } catch (err) {
      console.log(err);
    }
  },
  data() {
    return {
      showPassword: false,
      showNewPassword: false,
      showConfirm: false,
      password: "",
      newPassword: "",
      confirmPassword: "",
      newPhoneNumber: "",
      form: {
        email: this.$store.state.account.user.items.email,
        password: this.$store.state.account.user.items.password,
        provider: this.$store.state.account.user.items.provider,
        fullname: this.$store.state.account.user.items.fullname,
        phoneNumber: this.$store.state.account.user.items.phoneNumber,
        gender: this.$store.state.account.user.items.gender,
        bio: this.$store.state.account.user.items.biography
          ? this.$store.state.account.user.items.biography
          : "",
        birthdate: this.$store.state.account.user.items.birthdate
          ? this.$store.state.account.user.items.birthdate
          : null,
        city: this.$store.state.account.user.items.city
          ? this.$store.state.account.user.items.city
          : null,
        picture: this.$store.state.account.user.items.picture,
        university: !this.$store.state.account.user.items.university
          ? null
          : {
              code: this.$store.state.account.user.items.university.id,
              label: this.$store.state.account.user.items.university.name,
            },
        work: {
          code: this.$store.state.account.user.items.occupation,
          label: this.$store.state.account.user.items.occupation,
        },
        otherWork:
          this.$store.state.account.user.items.occupation === "Lainnya"
            ? this.$store.state.account.user.items.occupation
            : null,
        interests: this.$store.state.account.user.items.interests.map(
          (item) => {
            return {
              code: item.id,
              label: item.title,
            };
          }
        ),
      },
      isEdit: false,
      picturePreview: this.$store.state.auth.user.picture,
    };
  },
  watch: {
    newPhoneNumber(newValue, oldValue) {
      if (newValue.substring(0, 1) != "0") {
        this.newPhoneNumber = "";
      }
    },
  },
  methods: {
    validateNumber(event) {
      let keyCode = event.keyCode;
      if (keyCode == 45 || keyCode == 43) {
        event.preventDefault();
      }
      if (event.target.value.length >= 13) {
        event.preventDefault();
      }
    },
    updateProfile() {
      let interests = [];
      this.form.interests.forEach((item) => {
        interests.push({
          id: item.code,
        });
      });
      let formData = {
        provider: this.form.provider,
        email: this.form.email,
        fullname: this.form.fullname,
        birthdate: this.form.birthdate,
        gender: this.form.gender,
        city: this.form.city.label,
        occupation:
          this.form.work.label === "Lainnya"
            ? this.form.otherWork
            : this.form.work.label,
        university:
          this.form.work.label !== "Mahasiswa"
            ? null
            : {
                id: this.form.university.code,
              },
        interests: interests,
        biography: this.form.bio,
      };
      this.$axios
        .$patch(`${config.USER_SERVICE_API}/api/users`, formData)
        .then((response) => {
          location.reload();
          this.$toast.success("Selamat! Perubahan data berhasil diperbarui");
        })
        .catch((e) => {
          this.$toast.error("Maaf, Nomor handphone yang anda masukkan salah.");
        });
    },
    updatePassword() {
      let formData = {
        password: this.newPassword,
      };
      this.$axios
        .$patch(`${config.USER_SERVICE_API}/api/users`, formData)
        .then((response) => {
          this.$toast.success("Password anda berhasil diperbarui...");
          location.reload();
        })
        .catch((e) => {
          this.$toast.error("Opps, password salah...");
        });
    },
    updatePhoneNumber() {
      let formData = {
        phoneNumber: this.newPhoneNumber,
      };
      this.$axios
        .$patch(`${config.USER_SERVICE_API}/api/users`, formData)
        .then((response) => {
          this.$toast.success("Nomor Handphone anda berhasil diperbarui...");
          location.reload();
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error(
            "Opps, nomor handphone yang anda masukkan sudah terdaftar ..."
          );
        });
    },
    moveUp() {
      window.scrollTo(0, 0);
    },
    previewImage: function (event) {
      let input = event.target;
      let that = this;
      if (input.files && input.files[0]) {
        let src = URL.createObjectURL(input.files[0]);
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let userImage = new Image();
        userImage.src = src;

        userImage.onload = function () {
          canvas.width = userImage.width;
          canvas.height = userImage.height;
          ctx.drawImage(userImage, 0, 0);
          let webpImage = canvas.toDataURL("image/webp");
          that.form.picture = that.dataURLtoBlob(webpImage);
          that.picturePreview = webpImage;

          var formData = new FormData();
          formData.append("file", that.form.picture);
          that.$axios
            .$post(`${config.USER_SERVICE_API}/api/users/avatar`, formData)
            .then((res) => {
              that.$toast.success("Gambar berhasil di ubah...!");
            })
            .catch((e) => {
              that.$toast.error("Gambar gagal di ubah...!");
            });
        };
      }
    },
    dataURLtoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    toggleInterest(data) {
      if (
        this.form.interests.filter((item, idx) => item.code == data.code)
          .length > 0
      ) {
        for (const i in this.form.interests) {
          if (this.form.interests[i].code === data.code) {
            this.form.interests.splice(i, 1);
          }
        }
      } else {
        this.form.interests.push(data);
      }
    },
    logout() {
      if (this.$store.state.auth.user.logout !== "local") {
        this.$fire.auth.signOut();
      }
      this.$auth.logout();
    },
    async initData() {
      await this.$store.dispatch("account/getUser");
      await this.$store.dispatch("account/getListInterests");
      await this.$store.dispatch("account/getListUniversity");
      await this.$store.dispatch("account/getLisCities");
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.content-item {
  .modal-content {
    background: white;
    height: fit-content;
    padding: 30px;
    border-radius: 10px !important;
    @media only screen and (max-width: 1024px) {
      width: 85%;
      padding: 25px;
    }

    .title-wrapper {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 32px;
        font-weight: 500;
        color: #4a25aa;
        margin-bottom: 15px;
        @media only screen and (max-width: 1024px) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }

      .close-modal {
        height: fit-content;
        cursor: pointer;
      }
    }

    .box {
      margin-bottom: 16px;

      label {
        font-size: 14px;
        color: #0c0b0d;
        display: block;
        margin-bottom: 8px;
        @media only screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }

      .input-wrapper {
        position: relative;

        input {
          width: 100%;
          border: none;
          border-bottom: 1px solid lightgray;
          padding: 4px 35px 4px 0;
          font-size: 14px;
          color: #0c0b0d;
          display: block;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }

          &:focus {
            outline: none;
          }

          &.wrong {
            border-color: red;
          }
        }

        #new-phone-number {
          padding-right: 0;
        }

        svg {
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%);
          width: 25px;
          cursor: pointer;
        }
      }
    }
  }
  .program-nav {
    display: flex;
    height: 51px;
    position: relative;
    margin-bottom: 32px;
    @media only screen and (max-width: 1024px) {
      height: 46px;
    }
    &::before {
      content: "";
      position: absolute;
      background-color: #e0e0e0;
      width: 100%;
      height: 2px;
      bottom: 0;
      z-index: 0;
    }
    .box {
      display: flex;
      align-items: center;
      width: fit-content;
      font-size: 16px;
      font-weight: 700;
      color: #9e9e9e;
      border-bottom: 4px solid transparent;
      padding: 0 20px;
      margin: 0 20px;
      z-index: 1;
      position: relative;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
        border-width: 3px;
      }
      &:first-child {
        padding-left: 0;
        margin-left: 0;
      }

      &:hover {
        color: #8166c8 !important;
        border-color: #8166c8;
      }

      &:active {
        color: #6242b4 !important;
        border-color: #8166c8;
      }

      &.Link-exact-active {
        color: #4a25aa !important;
        border-color: #4a25aa;

        &:hover {
          color: #4a25aa !important;
        }
      }
    }
  }
}

.w-25 {
  width: 10% !important;
}

.box-user-dashboard {
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  padding: 30px;
  background: #fff;
}

.image-data {
  border-radius: 50%;
  width: 201px;
  height: 201px;
  @media only screen and (max-width: 1024px) {
    width: 150px;
    height: 150px;
  }
}

.circle-grey {
  background-color: #e0e0e0;
  border-radius: 50%;
  width: 201px;
  height: 201px;
}

.bi-camera-fill {
  color: #b1b1b1;
  font-size: 72px;
  position: relative;
  top: 46px;
}

.text-box {
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 4px;
}

.form-control {
  background: #ffffff !important;
}

.danger {
  &:focus {
    border-color: #e73638;
  }
}

.warningText {
  background-color: #ffe900 !important;
}

.dangerText {
  background-color: #e73638 !important;
  color: #fafafa !important;
}

.button-edit-wrapper {
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .btn-one {
    cursor: pointer;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 10px;
    }
  }
}

.table {
  margin: 0;

  tbody {
    tr {
      @media only screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
      }

      &:last-child {
        margin: 0;
      }

      td {
        @media only screen and (max-width: 1024px) {
          padding: 0;
        }

        &.label {
          font-size: 14px;
          font-weight: 500;
          color: #616161;
          margin-bottom: 8px;
        }

        &.password-hash {
          word-wrap: break-word;
          word-wrap: break-word;
          -ms-word-break: break-all;
          word-break: break-all;
          word-break: break-word;
          -ms-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto;
          display: flex;
          @media only screen and (max-width: 1024px) {
            justify-content: space-between;
          }

          .password-text {
            font-size: 12px;
            margin: 0 20px 0 0;
          }

          .update-password {
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
              width: 20px;
            }

            .update {
              font-size: 12px;
              margin: 0;
              margin-left: 10px;
              @media only screen and (max-width: 1024px) {
                display: none;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.btn-one {
  height: 51px;
  padding: 0 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media only screen and (max-width: 1024px) {
    height: 40px;
  }

  &:hover {
    background: #8166c8;
  }

  &:active {
    background: #6242b4;
  }

  &.disable {
    pointer-events: none;
    color: #9e9e9e;
    background-color: #e0e0e0;
    border-color: #e0e0e0;
  }
}

.btn-two {
  height: 51px;
  padding: 0 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4a25aa;
  color: #4a25aa;
  cursor: pointer;
  @media only screen and (max-width: 1024px) {
    height: 40px;
  }

  &:hover {
    color: #8166c8;
    border-color: #8166c8;
  }

  &:active {
    color: #4a25aa;
    border-color: #4a25aa;
  }
}

.jobs {
  @media only screen and (max-width: 1024px) {
    margin-top: 16px;
  }
}

.custom-file {
  width: fit-content;
}

.btn-file {
  border: 1px solid #d6d6d6;
  color: #4a25aa;
  background-color: #f1f1f1;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 4px;

  &.disable {
    color: #9e9e9e;
    background-color: #e0e0e0;
    pointer-events: none;
  }
}

.btn-interest-top {
  color: #9e9e9e;
  background-color: #e0e0e0;
  border-radius: 15px;
  text-align: center;
  margin-right: -4px;
  padding: 5px 30px;
  cursor: pointer;
}

.btn-interest-bottom {
  color: white;
  background-color: #4a25aa;
  border-radius: 15px;
  text-align: center;
  margin-right: -4px;
  padding: 5px 23px;
}

.btn-active_ {
  color: white;
  background-color: #4a25aa;
}

.counter-text {
  width: 57px;
  height: 19px;
  background-color: #fafafa;
  float: right;
  border-radius: 5px;
}

.row-input {
  @media only screen and (max-width: 1024px) {
    flex-direction: column !important;
  }

  .input-wrapper {
    @media only screen and (max-width: 1024px) {
      margin-bottom: 16px;
    }
  }
}

.v-select {
  &.disable {
    pointer-events: none;
  }
}
</style>
